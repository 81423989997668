<template>
  <b-sidebar
    id="element-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Marca"
            label-for="marca"
          >
            <v-select
              id="marca"
              v-model="marcaSel"
              label="descripcion"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="marcas"
            />
          </b-form-group>

          <b-form-group
            label="Tipo de Producto"
            label-for="tipoProducto"
          >
            <v-select
              id="tipoProducto"
              v-model="tipoProductoSel"
              :reduce="m => m.id"
              label="title"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tiposProducto"
            />
          </b-form-group>

          <!-- Descripcion -->
          <validation-provider
            #default="validationContext"
            name="Descripcion"
            rules="required"
          >
            <b-form-group
              label="Descripción"
              label-for="descripcion"
            >
              <b-form-input
                id="descripcion"
                v-model="formData.descripcion"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Descripcion -->
          <validation-provider
            #default="validationContext"
            name="Capacidad"
            rules="required"
          >
            <b-form-group
              label="Capacidad (KG)"
              label-for="capacidad"
            >
              <b-form-input
                id="capacidad"
                v-model="formData.capacidad"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                type="number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const blankData = {
      nombre: '',
      label: '',
      icono: '',
    }
    const marcas = ref([])
    const marcaSel = ref({})
    const tiposProducto = ref([
      { id: 1, title: 'CILINDRO' },
      { id: 2, title: 'GRANEL' },
    ])
    const tipoProductoSel = ref({ id: 1, title: 'CILINDRO' })

    const formData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      formData.value = JSON.parse(JSON.stringify(blankData))
    }

    const getMarcas = async () => {
      await store.dispatch('catalogo/MARCA_FIND_ALL', {
        query: '',
        page: -1,
        limit: -1,
        tipo: 'cmb',
        sortBy: 'idMarca%7CASC',
      })
        .then(response => {
          if (response) {
            marcas.value = response
          }
        })
    }

    const onSubmit = () => {
      if (marcaSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar marca de la presentación.' } } })
        return
      }
      if (tipoProductoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar tipo de producto de la presentación.' } } })
        return
      }
      let service = 'catalogo/PRESENTACION_CREATE'
      if (props.formType === 'edit') {
        service = 'catalogo/PRESENTACION_UPDATE'
      }
      formData.value.marca = marcaSel.value
      formData.value.tipoProducto = tipoProductoSel.value
      store.dispatch(service, formData.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-toggle-sidebar', false)
        })
    }

    const toggleSidebar = val => {
      emit('update:is-toggle-sidebar', val)
      getMarcas()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Presentación'
      }
      if (props.formType === 'edit') {
        formData.value = {
          idPresentacion: props.dataEdit.idPresentacion,
          descripcion: props.dataEdit.descripcion,
          capacidad: props.dataEdit.capacidad,
          marca: props.dataEdit.marca,
          estado: props.dataEdit.estado,
        }
        marcaSel.value = props.dataEdit.marca
        tipoProductoSel.value = props.dataEdit.tipoProducto
        titleForm.value = 'Editar Presentación'
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      formData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      toggleSidebar,
      titleForm,
      getMarcas,
      marcas,
      marcaSel,
      tiposProducto,
      tipoProductoSel,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#element-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
